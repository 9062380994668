import dayjs, { Dayjs } from 'dayjs';

export const ONE_DAY_IN_SECONDS = 60 * 60 * 24;

export default function getNow() {
  return new Date().getTime();
}

export type DateOrTimeInput = string | number | Date | Dayjs;

export const getMostRecent = (date1: DateOrTimeInput, date2: DateOrTimeInput): Dayjs => {
  if (!date1 && !date2) {
    return;
  }
  if (!date2) {
    return dayjs(date1);
  }
  if (!date1) {
    return dayjs(date2);
  }
  const day1 = dayjs(date1);
  const day2 = dayjs(date2);

  return day1.isAfter(day2) ? day1 : day2;
};

export const formatToIso8601 = (date: DateOrTimeInput) => {
  if (!date) {
    return;
  }
  return dayjs(date).format();
};

export const formatToIsoDateZ = (date: DateOrTimeInput) => {
  if (!date) {
    return;
  }
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
};

export const formatToPublishedDate = (date: DateOrTimeInput) => {
  return dayjs(date).format('MMM D, YYYY');
};
